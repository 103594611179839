'use strict';

const processInclude = require('base/util');
const tileImage = require('utils/tileImage');

/**
 * Init einstein product recommendation slick carousel
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {void}
 */
function initEinsteinProductCarouselSlick($parentElement) {
    const $carouselWrapper = $($parentElement);
    const $carousel = $carouselWrapper.find('.product-grid');
    const $progressBar = $carousel.closest('.einstein-recommendation').find('.progress');
    const defaultSettings = {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        focusOnSelect: false,
        infinite: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1439,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1679,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3
                }
            }
        ]
    };

    const carouselSettings = $carouselWrapper.data('carousel-settings');
    let settings = {};

    if (carouselSettings && typeof carouselSettings === 'object') {
        settings = carouselSettings;
    } else if (carouselSettings && carouselSettings.length) {
        settings = JSON.parse(carouselSettings);
    } else {
        settings = defaultSettings;
    }

    // initialize slick carousel
    $carousel.slick(settings);

    // secondary image hover effect
    $carousel.find('.image-container').each(function (index, element) {
        tileImage.initHoverImageContainer($(element));
    });

    $carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        let calc;

        if (nextSlide === 0) {
            calc = 0;
        } else {
            calc = ((nextSlide + 1) / (slick.slideCount - slick.options.slidesToShow + 1)) * 100;
        }

        $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc);
    });
}

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    let einsteinUtils = window.CQuotient;

    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {string} filterRecommender string recommender id from filter item
 */
function fillDomElement(einsteinResponse, $parentElement, filterRecommender) {
    let recommender = filterRecommender || $parentElement.data('recommender');
    let recommendedProducts = einsteinResponse[recommender].recs;

    if (recommendedProducts && recommendedProducts.length > 0) {
        let template = $parentElement.data('template');
        let components = [];

        components = recommendedProducts.map(function (recommendedProduct) {
            let tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        let url = new URL($parentElement.data('product-load-url'));

        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', $parentElement.data('limit'));
        url.searchParams.append('recommender', recommender);

        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            success: function (html) {
                let $html = $('<div>' + html + '</div>');
                $html.find('.product').wrap('<div class="col product-item"></div>');
                let $carousel = $parentElement.find('.product-grid');
                if ($carousel.hasClass('slick-initialized')) {
                    $carousel.slick('unslick');
                    $carousel.empty();
                }
                $carousel.empty();
                $carousel.append($html.unwrap().html());
                initEinsteinProductCarouselSlick($parentElement);
                $parentElement.spinner().stop();
            },
            error: function () {
                /* if no recommendations found, remove the carousel component */
                $parentElement.spinner().stop();
                $parentElement.closest('.experience-component').remove();
            }
        });
    } else {
        /* if no recommendations found, remove the carousel component */
        $parentElement.spinner().stop();
        $parentElement.closest('.experience-component').remove();
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    let recommender = $parentElement.data('recommender');
    let params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
        $parentElement.spinner().stop();
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id: $parentElement.data('primary-product-id'),
        sku: $parentElement.data('secondary-product-id'),
        type: $parentElement.data('alternative-group-type'),
        alt_id: $parentElement.data('alternative-group-id')
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('category-id') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    let einsteinUtils = getEinsteinUtils();

    if (einsteinUtils) {
        let $recommendationTiles = $('.einstein-product-carousel');
        $recommendationTiles.each(function () {
            let $parentElement = $(this);
            let hasFilters = $parentElement.closest('.einstein-recommendation').find('.einstein-category-filter-item').length > 0;
            $parentElement.spinner().start();

            if (hasFilters) {
                processCategoryFilterItems($parentElement, einsteinUtils);
            } else {
                if ($parentElement.hasClass('product-recommender')) {
                    return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
                } else if ($parentElement.hasClass('category-recommender')) { // eslint-disable-line no-else-return
                    return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
                }

                return processRecommendationsTile($parentElement, einsteinUtils);
            }

            return null;
        });
    } else {
        /* if can not find einstein utils tries again after 500ms */
        setTimeout(function () {
            loadRecommendations();
        }, 500);
    }
}

/**
* Processes category filter items and makes a recommendation request to Einstein
* @param {JQuery} $parentElement - The parent element containing the category filter items
* @param {Object} einsteinUtils - Utility object for interacting with Einstein API
*/
function processCategoryFilterItems($parentElement, einsteinUtils) {
    let $filterItems = $parentElement.closest('.einstein-recommendation').find('.einstein-category-filter-item');
    $filterItems.on('click', function () {
        let $filterItem = $(this);

        if ($filterItem.hasClass('selected')) {
            return;
        }

        $filterItems.removeClass('selected');
        $filterItem.addClass('selected');

        let recommender = $filterItem.data('recommender');
        let params = {
            userId: einsteinUtils.getCQUserId(),
            cookieId: einsteinUtils.getCQCookieId(),
            ccver: '1.01',
            anchors: createCategoryAnchor($filterItem)
        };

        /**
        * Processes a recommendation responses
        * @param {Object} einsteinResponse cquotient object
        */
        function recommendationsReceived(einsteinResponse) {
            fillDomElement(einsteinResponse, $parentElement, recommender);
            $parentElement.spinner().stop();
        }

        if (einsteinUtils.getRecs) {
            einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
        } else {
            einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
            einsteinUtils.widgets.push({
                recommenderName: recommender,
                parameters: params,
                callback: recommendationsReceived
            });
        }
    });

    setTimeout(function () {
        if ($filterItems.length) {
            $($filterItems[0]).trigger('click');
        }
    }, 0);
}
/**
 * Load recommendations after document is ready
 */
$(document).ready(function () {
    /* load recommendations on page load, setTimeout 0 is used to load recommendations on the next tick  */
    setTimeout(function () {
        loadRecommendations();
        processInclude(require('./product/wishlistHeart'));
        processInclude(require('./product/quickView'));
    }, 0);
});
