'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    if (data.success) {
        if (icon.hasClass('fa-heart-icon')) {
            icon.removeClass('fa-heart-icon').addClass('fa-heart-icon-on');
        }
    }

    if ($('.wishlist-product-heart-messages').length === 0) {
        $('body').append('<div class="wishlist-product-heart-messages"></div>');
    }

    if ($('.wishlist-product-heart-alert').length === 0) {
        $('.wishlist-product-heart-messages')
            .append('<div class="wishlist-product-heart-alert text-center">' + data.msg + '</div>');
    }

    setTimeout(function () {
        $('.wishlist-product-heart-messages').remove();
    }, 3000);
}

/**
 * Handles the removal of a product from the wishlist and changes the heart icon color back to normal.
 * @param {Object} data - The data returned from the server indicating the result of the removal operation.
 * @param {jQuery} icon - The jQuery object representing the heart icon element.
 * @returns {void}
 */
function removeFromWishlist(data, icon) {
    $.spinner().stop();
    if (data.success) {
        if (icon.hasClass('fa-heart-icon-on')) {
            icon.removeClass('fa-heart-icon-on').addClass('fa-heart-icon');
        }
    }
}

module.exports = {
    toggleWishlist: function () {
        $('body').on('click', '.wishlistHeart', function (e) {
            e.stopPropagation();
            e.preventDefault();
            var $this = $(this);
            var routeType = 'post';
            var icon = $this.find($('i'));
            var url = $this.attr('href');
            var removeFromWishlistUrl = $this.data('removewishlist');
            var pid = $this.data('pid') || $this.closest('.product').data('pid') || $this.closest('.product-detail').data('pid');
            var optionId = $this.closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $this.closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;

            if (icon.hasClass('fa-heart-icon-on')) {
                url = removeFromWishlistUrl;
                routeType = 'get';
            }

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: routeType,
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    if (data.notAddedToWishlist) {
                        $('body').trigger('wishlist:addItem', data);
                        displayMessageAndChangeIcon(data, icon);
                    } else {
                        removeFromWishlist(data, icon);
                    }
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });
    }
};
